import { createRouter, createWebHistory } from "vue-router";

// routing - define path, name
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../Layout/Dashboardlayout.vue"),
  },
  {
    path: "/circularProfileInformation",
    name: "circularProfileInformation",
    component: () => import("../components/CircularProfileInformation.vue"),
  },
  {
    path: "/appliedSuccessMessage",
    name: "appliedSuccessMessage",
    component: () => import("../components/AppliedSuccessMessage.vue"),
  },

  {
    path: "/users",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/users",
        name: "users",
        component: () => import("../components/User/User.vue"),
      },
    ],
  },
  {
    path: "/editEmpInfo",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/editEmpInfo",
        name: "EditEmployeeInformation",
        component: () => import("../components/EditEmployeeInfo/EditEmployeeInfo.vue"),
      },
    ],
  },

  {
    path: '/paymentType',
    
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
        path: '/paymentType',
        name: 'paymentType',
        component: () => import('../components/PaymentType/PaymentType.vue'),
      }
    ]
  },

  {
    path: '/paymentTerm',
    
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
        path: '/paymentTerm',
        name: 'paymentTerm',
        component: () => import('../components/PaymentTerm/PaymentTerm.vue'),
      }
    ]
  },

  {
    path: '/country',
    
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
        path: "/country",
        name: "country",
        component: () => import("../components/Country.vue"),
      },
    ],
  },

  {
    path: "/gender",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/gender",
        name: "gender",
        component: () => import("../components/Gender/Gender.vue"),
      },
    ],
  },

  {
    path: "/identificationType",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/identificationType",
        name: "identificationType",
        component: () =>
          import("../components/IdentificationType/IdentificationType.vue"),
      },
    ],
  },

  {
    path: "/maritialStatus",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/maritialStatus",
        name: "maritialStatus",
        component: () =>
          import("../components/MaritialStatus/MaritialStatus.vue"),
      },
    ],
  },

  {
    path: "/religion",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/religion",
        name: "religion",
        component: () => import("../components/Religion/Religion.vue"),
      },
    ],
  },

  {
    path: "/bloodGroup",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/bloodGroup",
        name: "bloodGroup",
        component: () => import("../components/BloodGroup/BloodGroup.vue"),
      },
    ],
  },

  {
    path: "/attendance",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/attendance",
        name: "attendance",
        component: () => import("../components/Attendance.vue"),
      },
    ],
  },

  {
    path: "/todayAttendance",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/todayAttendance",
        name: "todayAttendance",
        component: () => import("../components/TodayAttendance.vue"),
      },
    ],
  },

  {
    path: "/todayAbsence",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/todayAbsence",
        name: "todayAbsence",
        component: () => import("../components/Absence.vue"),
      },
    ],
  },
  

  {
    path: "/typeOfPosition",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/typeOfPosition",
        name: "typeOfPosition",
        component: () => import("../components/TypeOfPosition.vue"),
      },
    ],
  },
  {
    path: "/unit",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/unit",
        name: "unit",
        component: () => import("../components/Unit.vue"),
      },
    ],
  },
  {
    path: "/workinghour",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/workinghour",
        name: "workinghour",
        component: () => import("../components/WorkingHour.vue"),
      },
    ],
  },
  {
    path: "/department",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/department",
        name: "Department",
        component: () => import("../components/Department.vue"),
      },
    ],
  },
  {
    path: "/holiday",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/holiday",
        name: "Holiday",
        component: () => import("../components/Holiday.vue"),
      },
    ],
  },
  {
    path: "/positionstatus",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/positionstatus",
        name: "PositionStatus",
        component: () => import("../components/PositionStatus.vue"),
      },
    ],
  },
  {
    path: "/recruitmentplans",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/recruitmentplans",
        name: "RecruitmentPlans",
        component: () => import("../components/RecruitmentPlans.vue"),
      },
    ],
  },
  {
    path: "/employeerequisitionform",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/employeerequisitionform",
        name: "employeerequisitionform",
        component: () => import("../components/EmployeeRequisitionForm.vue"),
      },
    ],
  },

  {
    path: "/circular",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/circular",
        name: "circular",
        component: () => import("../components/Circular.vue"),
      },
    ],
  },
  {
    path: "/requisitionlist",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/requisitionlist",
        name: "requisitionlist",
        component: () => import("../components/RequisitionList.vue"),
      },
    ],
  },
  {
    path: "/recommendedlist",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/recommendedlist",
        name: "recommendedlist",
        component: () => import("../components/RecommendedList.vue"),
      },
    ],
  },

  {
    path: "/approvedlist",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/approvedlist",
        name: "approvedlist",
        component: () => import("../components/ApprovedList.vue"),
      },
    ],
  },
  {
    path: "/circulardetails",
    component: () => import("../Layout/Fullpagelayout.vue"),
    children: [
      {
        path: "/circulardetails",
        name: "circulardetails",
        component: () => import("../components/CircularList.vue"),
      },
    ],
  },

  {
    path: "/",
    name: "Login",
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/requisitionapprove",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/requisitionapprove",
        name: "requisitionapprove",
        component: () => import("../components/ApprovedList.vue"),
      },
    ],
  },

  {
    path: "/circularapplication",
    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/circularapplication",
        name: "circularapplication",
        component: () => import("../components/ApplicationList.vue"),
      },
    ],
  },

  {
    path: "/designation",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/designation",
        name: "designation",
        component: () => import("../components/Designation.vue"),
      },
    ],
  },

  {
    path: "/leavetype",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/leavetype",
        name: "leavetype",
        component: () => import("../components/LeaveType.vue"),
      },
    ],
  },
  {
    path: "/leaveapplication",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/leaveapplication",
        name: "leaveapplication",
        component: () => import("../components/LeaveApplication.vue"),
      },
    ],
  },
  

  {
    path: '/employee',
    component: () => import('../Layout/Dashboardlayout.vue'),
    children:[
      {
      path: '/employee',
      name: 'employee',
      component: () => import('../components/Employees.vue'),
      }
    ]
  },
  {
    path: "/approvedleave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/approvedleave",
        name: "approvedleave",
        component: () => import("../components/ApprovedLeaves.vue"),
      },
    ],
  },
  {
    path: "/leavereport",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/leavereport",
        name: "leavereport",
        component: () => import("../components/LeaveReport.vue"),
      },
    ],
  },
  {
    path: "/pendingleave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/pendingleave",
        name: "pendingleave",
        component: () => import("../components/PendingLeaves.vue"),
      },
    ],
  },
  {
    path: "/forwardedleave",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/forwardedleave",
        name: "forwardedleave",
        component: () => import("../components/ForwardedLeaves.vue"),
      },
    ],
  },

  {
    path: "/transfertype",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/transfertype",
        name: "transfertype",
        component: () => import("../components/TransferType/TransferType.vue"),
      },
    ],
  },

  {
    path: "/transferEmployee",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/transferEmployee",
        name: "transferEmployee",
        component: () => import("../components/Transfer/SearchEmployeeInformation.vue"),
      },
    ],
  },
  {
    path: "/branch",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/branch",
        name: "branch",
        component: () => import("../components/Branch/Branch.vue"),
      },
    ],
  },
  {
    path: "/teamLead",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/teamLead",
        name: "teamLead",
        component: () => import("../components/TeamLead/TeamLead.vue"),
      },
    ],
  },
  {
    path: "/allCircularList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/allCircularList",
        name: "allCircularList",
        component: () => import("../components/AllCircularList.vue"),
      },
    ],
  },
  {
    path: "/applicantsPendingList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/applicantsPendingList",
        name: "applicantsPendingList",
        component: () => import("../components/ApplicantsPendingList.vue"),
      },
    ],
  },
  {
    path: "/shortlistedApplicants",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/shortlistedApplicants",
        name: "shortlistedApplicants",
        component: () => import("../components/ShortlistedApplicants.vue"),
      },
    ],
  },


  {
    path: "/applicantSelectedForInterview",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/applicantSelectedForInterview",
        name: "applicantSelectedForInterview",
        component: () => import("../components/ApplicantSelectedForInterview.vue"),
      },
    ],
  },
  {
    path: "/markAsEmployee",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/markAsEmployee",
        name: "markAsEmployee",
        component: () => import("../components/MarkAsEmployee.vue"),
      },
    ],
  },
  {
    path: "/applyResignation",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/applyResignation",
        name: "applyResignation",
        component: () => import("../components/Resignation/ApplyResignation.vue"),
      },
    ],
  },
  {
    path: "/resignationPendingListForTeamLead",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationPendingListForTeamLead",
        name: "resignationPendingListForTeamLead",
        component: () => import("../components/Resignation/ResignationPendingListForTeamLead.vue"),
      },
    ],
  },
  {
    path: "/resignationPendingListForIT",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationPendingListForIT",
        name: "resignationPendingListForIT",
        component: () => import("../components/Resignation/ResignationPendingListForIT.vue"),
      },
    ],
  },
  {
    path: "/resignationPendingListForAccounts",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationPendingListForAccounts",
        name: "resignationPendingListForAccounts",
        component: () => import("../components/Resignation/ResignationPendingListForAccounts.vue"),
      },
    ],
  },
  {
    path: "/resignationApprovedListForTeamLead",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationApprovedListForTeamLead",
        name: "resignationApprovedListForTeamLead",
        component: () => import("../components/Resignation/ResignationApprovedListForTeamLead.vue"),
      },
    ],
  },
  {
    path: "/resignationApprovedListForIT",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationApprovedListForIT",
        name: "resignationApprovedListForIT",
        component: () => import("../components/Resignation/ResignationApprovedListForIT.vue"),
      },
    ],
  },
  {
    path: "/resignationApprovedListForAccounts",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationApprovedListForAccounts",
        name: "resignationApprovedListForAccounts",
        component: () => import("../components/Resignation/ResignationApprovedListForAccounts.vue"),
      },
    ],
  },
  
  {
    path: "/resignationPendingListForHR",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationPendingListForHR",
        name: "resignationPendingListForHR",
        component: () => import("../components/Resignation/ResignationPendingListForHR.vue"),
      },
    ],
  },

  {
    path: "/resignationApprovedListForHR",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationApprovedListForHR",
        name: "resignationApprovedListForHR",
        component: () => import("../components/Resignation/ResignationApprovedListForHR.vue"),
      },
    ],
  },

  {
    path: "/resignationPendingListForSeniorManager",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationPendingListForSeniorManager",
        name: "resignationPendingListForSeniorManager",
        component: () => import("../components/Resignation/ResignationPendingListForSeniorManager.vue"),
      },
    ],
  },

  {
    path: "/resignationApprovedListForSeniorManager",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/resignationApprovedListForSeniorManager",
        name: "resignationApprovedListForSeniorManager",
        component: () => import("../components/Resignation/ResignationApprovedListForSeniorManager.vue"),
      },
    ],
  },

  {
    path: "/probationaryEmployeeAssessmentFormForConfirmation",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/probationaryEmployeeAssessmentFormForConfirmation",
        name: "probationaryEmployeeAssessmentFormForConfirmation",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ProbationaryEmployeeAssessmentFormForConfirmation.vue"),
      },
    ],
  },

  {
    path: "/confirmationList",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/confirmationList",
        name: "confirmationList",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ConfirmationList.vue"),
      },
    ],
  },

  {
    path: "/confirmationReportForSeniorManager",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/confirmationReportForSeniorManager",
        name: "confirmationReportForSeniorManager",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ConfirmationReportForSeniorManager.vue"),
      },
    ],
  },

  {
    path: "/confirmationReportForCheifExecutiveOfficer",

    component: () => import("../Layout/Dashboardlayout.vue"),
    children: [
      {
        path: "/confirmationReportForCheifExecutiveOfficer",
        name: "confirmationReportForCheifExecutiveOfficer",
        component: () => import("../components/ProbationaryEmployeeAssessmentFormForConfirmation/ConfirmationReportForCheifExecutiveOfficer.vue"),
      },
    ],
  },

 


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
